import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { UserService } from "../service/user.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { switchMap, take } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  login_url = "";
  constructor(private angularFire: AngularFireAuth) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.angularFire.idToken.pipe(
      take(1),
      switchMap((token) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(request);
      })
    );
  }
}
